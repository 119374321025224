/* eslint-disable react/require-default-props */
import Head from "next/head";
import Router from "next/router";
import NProgress from "nprogress";

interface LayoutBaseProps {
  title?: string;
  description?: string;
  children: React.ReactNode;
}

export default function LayoutBase({
  children,
  title,
  description,
}: LayoutBaseProps): JSX.Element {
  if (Router.events) {
    Router.events.on("routeChangeStart", () => NProgress.start());
    Router.events.on("routeChangeComplete", () => NProgress.done());
    Router.events.on("routeChangeError", () => NProgress.done());
  }

  const htmlTitle = title ? `${title} - Attendly` : "Attendly";

  return (
    <>
      <Head>
        <title>{htmlTitle}</title>
        {description && <meta name="description" content={description} />}
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
      </Head>
      <main>{children}</main>
    </>
  );
}
